/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { Button, Row, Col } from 'reactstrap';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../components/loader/Loader';
import {
  deleteCommentsActionCreator,
  updateCommentsActionCreator
} from '../../../redux/slices/CommetsSlice';
import InputField from '../../input/inputField/InputField';
import FileUpload from '../../components/file-upload/FileUpload';
import { convertToFormData } from '../../helper/commonFunction';

function CommentForms({ handleClose, commentsData, view }) {
  const dispatch = useDispatch();
  const btnLoader = useSelector((state) => state.comments.btnLoader);

  const {
    control,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
    setValue
  } = useForm();

  const [resetFilePreview, setResetFilePreview] = useState();
  const [removedFileID, setRemovedFileID] = useState([]);
  const [fileValue, setFileValue] = useState(null);
  const childRef = useRef();

  useEffect(() => {
    if (view === 'commentsEdit') {
      setValue('comment', commentsData.comment);
      if (commentsData?.comment_attachments?.length) {
        const fileDataValue = [];
        const fileDetail = [];
        commentsData?.comment_attachments.forEach((attachment) => {
          fileDataValue.push(attachment?.file_name);
          fileDetail.push(attachment);
        });
        if (fileDataValue?.length) {
          setValue('wall_file', fileDataValue);
          setFileValue(fileDetail);
        }
      }
    }
  }, []);

  const handleCancel = () => {
    handleClose(null);
  };
  const onSubmit = (data) => {
    const request = {
      body: {
        comment: view === 'commentsEdit' ? data.comment : commentsData.comment
      }
    };
    request.id = commentsData.comment_id;
    if (view !== 'commentsEdit') {
      request.parentID = commentsData.parent_comment_id;
      request.postID = commentsData.post_id;
    }

    const formData = new FormData();
    const formValue = convertToFormData(request.body);
    if (data.wall_file?.length) {
      data.wall_file.forEach((wallFile) => {
        if (wallFile instanceof File) formData.append('attachment', wallFile);
        else if (wallFile?.actualFile instanceof File)
          formData.append('attachment', wallFile?.actualFile);
      });
    }

    if (removedFileID?.length) {
      formData.append('remove_attachment', JSON.stringify(removedFileID));
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const pair of formValue.entries()) {
      formData.append(pair[0], pair[1]);
    }

    request.body = formData;

    let actionCreator = null;
    if (view === 'commentsEdit') {
      actionCreator = dispatch(updateCommentsActionCreator(request));
    } else {
      // request.parentID = commentsData.parent_comment_id;
      // request.postID = commentsData.post_id;
      actionCreator = dispatch(deleteCommentsActionCreator(request));
    }
    actionCreator.unwrap().then((result) => {
      setResetFilePreview(!resetFilePreview);
      handleClose(result);
    });
  };

  const handleClick = () => {
    childRef.current.parentClick();
  };

  return (
    <div className="delete-wall-list-container">
      <Loader show={btnLoader} />

      <div className="">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            {view === 'commentsEdit' ? (
              <>
                <Col md={10}>
                  <Controller
                    name="comment"
                    control={control}
                    rules={{
                      required: true,
                      pattern: {
                        value: /.*\S.*/,
                        message: 'Please enter valid text.'
                      }
                    }}
                    render={({ field }) => (
                      <InputField
                        errors={errors.comment}
                        inputType="textarea"
                        rows="2"
                        field={field}
                      />
                    )}
                  />
                </Col>
                <Col sm={2}>
                  <Button
                    outline
                    type="button"
                    onClick={handleClick}
                    color="primary">
                    <i className="bi bi-upload " /> Upload File
                  </Button>
                </Col>
                <Col className="mt-3" sm={12}>
                  <Controller
                    name="wall_file[]"
                    control={control}
                    render={({ field: { onChange } }) => (
                      <FileUpload
                        errors={errors.wall_file}
                        field={onChange}
                        setError={setError}
                        clearErrors={clearErrors}
                        setValue={setValue}
                        fieldColumn="wall_file"
                        fileValue={fileValue}
                        setRemovedFileID={setRemovedFileID}
                        showPreview={false}
                        reset={resetFilePreview}
                        ref={childRef}
                        isMulti
                        handleClickFromParent
                      />
                    )}
                  />
                </Col>
              </>
            ) : (
              <h6>Are you sure you want to delete comment ?</h6>
            )}
          </Row>
          <Row className="pt-4">
            <Col md="auto">
              <Button
                outline
                color="primary"
                disabled={btnLoader}
                onClick={handleCancel}>
                Cancel
              </Button>
            </Col>
            <Col md="auto">
              <Button color="primary" disabled={btnLoader}>
                {view === 'commentsEdit' ? 'Update' : 'Delete'}
              </Button>
            </Col>
          </Row>
        </form>
      </div>
    </div>
  );
}

export default CommentForms;
