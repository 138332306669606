import { useDispatch } from 'react-redux';
import { useRef, useState } from 'react';
import { Button, Card, Col, Input, Row } from 'reactstrap';
import { useForm, Controller } from 'react-hook-form';
import { saveCommentActionCreator } from '../../../redux/slices/PostSlice';
import './Comment.scss';
import { getCommentsParentListActionCreator } from '../../../redux/slices/CommetsSlice';
import FileUpload from '../../components/file-upload/FileUpload';
import { convertToFormData } from '../../helper/commonFunction';

function Comment({ postData, getPostList }) {
  // const authState = useSelector((state) => state.login.loginDetails);
  const dispatch = useDispatch();
  const pattern = /.*\S.*/;
  const {
    control,
    watch,
    setError,
    clearErrors,
    handleSubmit,
    formState: { errors },
    setValue,
    reset
  } = useForm();

  const [resetFilePreview, setResetFilePreview] = useState();
  const childRef = useRef();

  const reloadComments = () => {
    getPostList(true).then(() => {
      const reqBody = {};
      reqBody.postID = postData.post_id;
      reqBody.parentID = 0;
      dispatch(getCommentsParentListActionCreator(reqBody));
    });
  };
  const onSubmit = (data) => {
    const request = {
      body: {
        post_id: postData.post_id,
        comment: data.comment,
        parent_comment_id: null
      }
    };

    const formData = new FormData();
    const formValue = convertToFormData(request.body);
    if (data.wall_file?.length) {
      data.wall_file.forEach((wallFile) => {
        if (wallFile instanceof File) formData.append('attachment', wallFile);
        else if (wallFile?.actualFile instanceof File)
          formData.append('attachment', wallFile?.actualFile);
      });
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const pair of formValue.entries()) {
      formData.append(pair[0], pair[1]);
    }

    request.body = formData;

    dispatch(saveCommentActionCreator(request))
      .unwrap()
      .then(() => {
        reloadComments();
        setResetFilePreview(!resetFilePreview);
        reset({
          comment: ''
        });
      });
  };

  const handleClick = () => {
    childRef.current.parentClick();
  };

  const resetForm = () => {
    setResetFilePreview(!resetFilePreview);
    reset({
      comment: ''
    });
  };

  return (
    <Card className="card-background">
      <div className="">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="comment"
            control={control}
            rules={{
              required: true
            }}
            render={({ field }) => (
              <Input
                className="border-0 p-1 comment-area font-regular"
                errors={errors.comment}
                name="comment"
                type="textarea"
                rows="2"
                placeholder="Type here...."
                {...field}
              />
            )}
          />
          <Controller
            name="wall_file[]"
            control={control}
            render={({ field: { onChange } }) => (
              <FileUpload
                errors={errors.wall_file}
                field={onChange}
                setError={setError}
                clearErrors={clearErrors}
                setValue={setValue}
                fieldColumn="wall_file"
                showPreview={false}
                reset={resetFilePreview}
                ref={childRef}
                isMulti
                handleClickFromParent
                // options={users}
                // setSelected={setSelectedTag}
              />
            )}
          />
          {/* <Button className="rotate-btn">
            <i className="bi bi-send fs-4 text-primary" />
          </Button> */}
          <Row>
            <Col sm="auto">
              {watch('comment') && pattern.test(watch('comment')) && (
                <Button
                  outline
                  type="button"
                  onClick={resetForm}
                  color="primary">
                  Cancel
                </Button>
              )}
            </Col>
            <Col sm="auto">
              {watch('comment') && pattern.test(watch('comment')) && (
                <Button
                  outline
                  type="button"
                  onClick={handleClick}
                  color="primary">
                  <i className="bi bi-upload " /> Upload File
                </Button>
              )}
            </Col>
            <Col sm="auto">
              {watch('comment') && pattern.test(watch('comment')) && (
                <Button color="primary">Post</Button>
              )}
            </Col>
          </Row>
        </form>
      </div>
    </Card>
  );
}

export default Comment;
