import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

import './Modal.scss';

function CustomModal({ showModal, setShowModal, heading, body, size = 'lg' }) {
  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <Modal
      contentClassName="custom-modal-style"
      size={size}
      isOpen={showModal}
      // eslint-disable-next-line react/jsx-boolean-value
      centered={true}>
      <ModalHeader toggle={handleClose}>{heading}</ModalHeader>
      <ModalBody>{body}</ModalBody>
    </Modal>
  );
}

export default CustomModal;
