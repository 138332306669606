import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Badge, Button, Col, Row } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import InputField from '../../input/inputField/InputField';
import {
  saveWallPostActionCreator,
  savepostThanksActionCreator,
  saveEndorsementPostActionCreator,
  saveAchievementPostActionCreator,
  saveKudosPostActionCreator,
  updateKudosActionCreator,
  updateEndorsementActionCreator,
  updateAchievementActionCreator,
  updateThanksActionCreator,
  updatePostActionCreator
} from '../../../redux/slices/PostSlice';
import TagPeople from '../tagPeople/TagPeople';
import { convertToFormData, listFormatter } from '../../helper/commonFunction';
import FileUpload from '../../components/file-upload/FileUpload';

function GeneralPostTypeField({
  postType,
  postDetail,
  refreshPost,
  users,
  handleCancel,
  handleClose,
  formView = 'addView'
}) {
  const authState = useSelector((state) => state.login.loginDetails);
  const btnLoader = useSelector((state) => state.post.btnLoader);
  const [removedFileID, setRemovedFileID] = useState([]);
  const [fileValue, setFileValue] = useState(null);
  const [selectedTag, setSelectedTag] = useState([]);
  const [resetFilePreview, setResetFilePreview] = useState();
  const { wallID } = useParams();
  const childRef = useRef();
  const {
    reset,
    control,
    handleSubmit,
    setError,
    clearErrors,
    // getValues,
    setValue,
    formState: { errors }
  } = useForm();

  const dispatch = useDispatch();

  const handleSelectedTags = (values) => {
    if (values?.find((option) => option.value === '*')) {
      setSelectedTag(users);
      setValue('taggedPeople', users, { shouldValidate: true });
    } else {
      setSelectedTag(values);
      setValue('taggedPeople', values, { shouldValidate: true });
    }
    // setSelectedTag(values);
    // setValue('taggedPeople', values, { shouldValidate: true });
  };

  const removeTaggedValue = (e) => {
    const { name: buttonName } = e.currentTarget;
    const removedValue = selectedTag.find((val) => val.value === buttonName);
    if (!removedValue) return;
    handleSelectedTags(selectedTag.filter((val) => val.value !== buttonName));
  };

  useEffect(() => {
    if (postDetail) {
      setValue('description', postDetail?.post_description);

      if (postDetail?.post_attachments?.length) {
        const fileDataValue = [];
        const fileDetail = [];
        postDetail?.post_attachments.forEach((attachment) => {
          fileDataValue.push(attachment?.file_name);
          fileDetail.push(attachment);
        });
        if (fileDataValue?.length) {
          setValue('wall_file', fileDataValue);
          setFileValue(fileDetail);
        }
      }

      if (postDetail?.tagged_employees?.length) {
        const filteredTaggedEmployees = listFormatter(
          postDetail?.tagged_employees,
          (taggedPerson) => ({
            id: taggedPerson.employee_id,
            label: taggedPerson?.name || '',
            value: taggedPerson?.name || ''
          })
        );
        handleSelectedTags(filteredTaggedEmployees);
      } else {
        setSelectedTag([]);
      }
    }
  }, [postDetail]);

  const resetForm = () => {
    reset({
      description: '',
      taggedPeople: ''
    });
    setSelectedTag([]);
  };

  useEffect(() => {
    resetForm();
    setResetFilePreview(!resetFilePreview);
  }, [postType]);

  const onSubmit = (data) => {
    const apiData = {
      body: {
        tagged_employee_id: [],
        description: data.description
      }
    };

    let actionCreator = null;
    let wallPostType = postType;

    if (formView === 'addView') {
      apiData.body.wall_id = wallID;
      apiData.body.posted_by = authState.employee_id;
    } else if (formView === 'editView') {
      wallPostType = postDetail.post_type;
      apiData.id = postDetail.post_id;
    }

    if (data?.taggedPeople) {
      let taggedEmployeeId = [];
      taggedEmployeeId = data.taggedPeople.map((tagedPerson) => tagedPerson.id);
      apiData.body.tagged_employee_id = taggedEmployeeId;
    }

    const formData = new FormData();
    const formValue = convertToFormData(apiData.body);
    if (data.wall_file?.length) {
      data.wall_file.forEach((wallFile) => {
        if (wallFile instanceof File) formData.append('attachment', wallFile);
        else if (wallFile?.actualFile instanceof File)
          formData.append('attachment', wallFile?.actualFile);
      });
    }
    if (removedFileID?.length) {
      formData.append('remove_attachment', JSON.stringify(removedFileID));
    }
    // eslint-disable-next-line no-restricted-syntax
    for (const pair of formValue.entries()) {
      formData.append(pair[0], pair[1]);
    }

    apiData.body = formData;

    if (wallPostType === 'Thanks') {
      if (formView === 'addView') {
        actionCreator = dispatch(savepostThanksActionCreator(apiData));
      } else {
        actionCreator = dispatch(updateThanksActionCreator(apiData));
      }
    } else if (wallPostType === 'Endorsement') {
      if (formView === 'addView') {
        actionCreator = dispatch(saveEndorsementPostActionCreator(apiData));
      } else {
        actionCreator = dispatch(updateEndorsementActionCreator(apiData));
      }
    } else if (wallPostType === 'Kudos') {
      if (formView === 'addView') {
        actionCreator = dispatch(saveKudosPostActionCreator(apiData));
      } else {
        actionCreator = dispatch(updateKudosActionCreator(apiData));
      }
    } else if (wallPostType === 'Post') {
      if (formView === 'addView') {
        actionCreator = dispatch(saveWallPostActionCreator(apiData));
      } else {
        // apiData.body.wall_id = wallID;
        // apiData.body.posted_by = authState.employee_id;
        actionCreator = dispatch(updatePostActionCreator(apiData));
      }
    } else if (wallPostType === 'Achievement') {
      if (formView === 'addView') {
        actionCreator = dispatch(saveAchievementPostActionCreator(apiData));
      } else {
        actionCreator = dispatch(updateAchievementActionCreator(apiData));
      }
    }

    actionCreator.unwrap().then(() => {
      if (formView === 'addView') {
        resetForm();
        setResetFilePreview(!resetFilePreview);
        refreshPost();
      } else if (formView === 'editView') {
        handleClose(apiData);
        // navigate(`/report-view-Curriculum/${postDetail?.post_id}`);
      }
    });
  };

  const handleClick = () => {
    childRef.current.parentClick();
  };

  return (
    <div className="post-form-container mt-3">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row className="pt-2">
          <Col>
            <Controller
              name="description"
              control={control}
              rules={{
                required: true,
                pattern: {
                  value: /.*\S.*/,
                  message: 'Please enter valid text.'
                }
              }}
              render={({ field }) => (
                <InputField
                  errors={errors.description}
                  className="text-area-field"
                  field={field}
                  name="description"
                  inputType="textarea"
                  placeholder="Type something..."
                  label={formView === 'editView' ? 'Description' : ''}
                />
              )}
            />

            {/* <Controller
                        name="description"
                        control={control}
                        rules={{
                          required: true
                        }}
                        render={({ field }) => (
                          <InputField
                            errors={errors.description}
                            inputType="textarea"
                            field={field}
                            label="Type something..."
                            // readOnly
                          />
                        )}
                      /> */}
          </Col>
          <Col
            lg="auto"
            className="d-flex align-items-start justify-content-center ">
            <Button
              outline
              type="button"
              className={formView === 'editView' ? 'mt-edit-btn ' : ''}
              onClick={handleClick}
              color="primary">
              <i className="bi bi-upload " /> Upload File
            </Button>
          </Col>
        </Row>
        <Row className="mt-3">
          <Controller
            name="wall_file[]"
            control={control}
            render={({ field: { onChange } }) => (
              <FileUpload
                errors={errors.wall_file}
                field={onChange}
                setError={setError}
                clearErrors={clearErrors}
                setValue={setValue}
                fieldColumn="wall_file"
                fileValue={fileValue}
                setRemovedFileID={setRemovedFileID}
                showPreview={false}
                reset={resetFilePreview}
                ref={childRef}
                isMulti
                handleClickFromParent
                // options={users}
                // setSelected={setSelectedTag}
              />
            )}
          />
        </Row>
        <Row className="mt-3 ">
          {formView === 'addView' ? (
            <Col
              md="auto"
              className="d-flex align-items-start justify-content-center">
              <Button color="primary" disabled={btnLoader}>
                Post
              </Button>
            </Col>
          ) : (
            ''
          )}
          <Col md={3} className="tp-head-col">
            <div>
              <Controller
                name="taggedPeople"
                control={control}
                rules={{
                  required: false
                }}
                render={({ field: { onChange } }) => (
                  <TagPeople
                    errors={errors.taggedPeople}
                    onChange={handleSelectedTags}
                    field={onChange}
                    isMulti
                    options={users}
                    value={selectedTag}
                    setSelected={setSelectedTag}
                  />
                )}
              />
            </div>
          </Col>
          <Col className="tag-people-col align-self-center">
            {selectedTag.map((val) => (
              <Badge key={val.value} className="tagged-badge me-1">
                {val.label}

                <Button
                  className="tagged-close"
                  name={val.value}
                  onClick={removeTaggedValue}>
                  <i className="bi bi-x-circle tag-cls-btn" />
                </Button>
              </Badge>
            ))}
          </Col>
        </Row>

        {formView === 'editView' ? (
          <Row className="btn-container mt-4">
            <Col md="auto">
              <Button
                outline
                color="primary"
                disabled={btnLoader}
                onClick={handleCancel}>
                Cancel
              </Button>
            </Col>
            <Col md="auto">
              <Button color="primary" disabled={btnLoader}>
                Update
              </Button>
            </Col>
          </Row>
        ) : (
          ''
        )}
      </form>
    </div>
  );
}

export default GeneralPostTypeField;
