export const locationFormatter = (data) => {
  const formattedData = data.map((location) => ({
    id: location.join_location_id,
    value: location.join_location,
    label: location.join_location
  }));
  return formattedData;
};

export const streamFormatter = (data) => {
  const formattedData = data.map((stream) => ({
    id: stream.stream_id,
    value: stream.stream,
    label: stream.stream
  }));
  return formattedData;
};

export const qualificationFormatter = (data) => {
  const formattedData = data.map((qualification) => ({
    id: qualification.qual_id,
    value: qualification.qualification,
    label: qualification.qualification
  }));
  return formattedData;
};

export const menteeListFormatter = (data) => {
  const formattedData = data.map((mentee) => ({
    id: mentee.employee_id,
    value: mentee.name,
    label: mentee.name,
    email: mentee.email
  }));
  return formattedData;
};

export const getMenteebyId = (list, id) => {
  const selectedMentee = list.find((element) => element.employee_id === id);
  return selectedMentee;
};
export const commonFormatter = (data) => {
  const formattedData = data.map((item) => ({
    id: item.employee_id,
    value: item.name,
    label: item.name,
    email: item.email
  }));
  return formattedData;
};
export const batchFormatter = (data) => {
  const formattedData = data.map((batch) => ({
    id: batch.batch_id,
    value: batch.batch_name,
    label: batch.batch_name
  }));
  return formattedData;
};
export const projectFormatter = (data) => {
  const formattedData = data.map((project) => ({
    id: project.project_id,
    value: project.project_name,
    label: project.project_name
  }));
  return formattedData;
};
export const wallListFormatter = (data) => {
  const formattedData = data.map((walllist) => ({
    id: walllist.wall_id,
    value: walllist.wall_name,
    label: walllist.wall_name
  }));
  return formattedData;
};
export const curriculumListFormatter = (data) => {
  const formattedData = data.map((curriculum) => ({
    id: curriculum.curriculum_id,
    value: curriculum.curriculum_name,
    label: curriculum.curriculum_name
  }));
  return formattedData;
};
export const rolesFormat = () => {
  // const roles = [
  //   {
  //     id: 1,
  //     value: 'Mentor',
  //     label: 'Mentor'
  //   },
  //   {
  //     id: 2,
  //     value: 'Mentee',
  //     label: 'Mentee'
  //   },
  //   {
  //     id: 3,
  //     value: 'Architect',
  //     label: 'Architect'
  //   },
  //   {
  //     id: 4,
  //     value: 'Designer',
  //     label: 'Designer'
  //   }
  // ];
  const roles = [
    {
      id: 1,
      value: 'Admin',
      label: 'Admin'
    },
    {
      id: 2,
      value: 'User',
      label: 'User'
    }
  ];
  return roles;
};

export const sessionListFormatter = (data) => {
  const formattedData = data.map((session) => ({
    id: session.session_id,
    value: session.session_name,
    label: session.session_name
  }));
  return formattedData;
};

export const trainerListFormatter = (data) => {
  const formattedData = data.map((trainer) => ({
    id: trainer.employee_id,
    value: trainer.name,
    label: trainer.name,
    email: trainer.email
  }));
  return formattedData;
};

export const formatOptionLabel = (option, { context }) =>
  context === 'value' ? option.label : `${option.label} - ${option.email}`;
