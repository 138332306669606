import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import SERVER_URL from '../../shared/constants/Constants';
import fetchUtility from '../../shared/helper/fetchUtility';

const initialState = {
  profileDetails: {},
  btnLoader: false,
  loading: false,
  error: ''
};

export const getProfileDetailsActionCreator = createAsyncThunk(
  'profile/profile_details',
  async (id) => {
    const { data } = await fetchUtility(
      'get',
      `${SERVER_URL.PROFILE_DETAILS}${id}`
    );
    return data;
  }
);
export const updateProfileDetailsActionCreator = createAsyncThunk(
  'profile/profile_details_update',
  async (params) => {
    const { data } = await fetchUtility(
      'put',
      `${SERVER_URL.PROFILE_DETAILS_UPDATE}${params.employee_id}`,
      params.body
    );
    return data;
  }
);

export const updateEnableNotificationActionCreator = createAsyncThunk(
  'profile/enable_notification',
  async (params) => {
    const { data } = await fetchUtility(
      'post',
      `${SERVER_URL.TAGGED_GENERAL_NOTIFICATION_ENABLE}`,
      params.body
    );
    return data;
  }
);
export const taggedNotificationActionCreator = createAsyncThunk(
  'profile/tagged_notification',
  async (params) => {
    const { data } = await fetchUtility(
      'post',
      `${SERVER_URL.TAGGED_NOTIFICATION_ENABLE}`,
      params.body
    );
    return data;
  }
);

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getProfileDetailsActionCreator.pending, (state) => {
      state.loading = true;
      // state.profileDetails = [];
    });
    builder.addCase(
      getProfileDetailsActionCreator.fulfilled,
      (state, action) => {
        state.loading = false;
        state.profileDetails = action.payload.result;
        state.error = '';
      }
    );
    builder.addCase(
      getProfileDetailsActionCreator.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      }
    );

    builder.addCase(updateProfileDetailsActionCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateProfileDetailsActionCreator.fulfilled, (state) => {
      state.loading = false;
      state.error = '';
    });
    builder.addCase(
      updateProfileDetailsActionCreator.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      }
    );

    builder.addCase(updateEnableNotificationActionCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      updateEnableNotificationActionCreator.fulfilled,
      (state) => {
        state.loading = false;
        state.error = '';
      }
    );
    builder.addCase(
      updateEnableNotificationActionCreator.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      }
    );

    builder.addCase(taggedNotificationActionCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(taggedNotificationActionCreator.fulfilled, (state) => {
      state.loading = false;
      state.error = '';
    });
    builder.addCase(
      taggedNotificationActionCreator.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      }
    );
  }
});

export default profileSlice.reducer;
