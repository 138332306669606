import React, { useEffect, useState } from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Tooltip
} from 'reactstrap';
import { useSelector } from 'react-redux';
import Avatar from 'react-avatar';
import CustomModal from '../../components/modal/Modal';
import WallPostForms from '../../../pages/wall/WallPostForms';
import DeleteWallPost from '../../../pages/wall/DeleteWallPost';
// import woman from '../../../assets/images/women.png';
import cheers from '../../../assets/images/cheers.png';
import Likes from '../likes/Likes';
import Comments from '../comments/Comments';
import Comment from '../comment/Comment';
import Pin from '../pin/Pin';
import './Kudos.scss';
import { UserComments } from '../comment/CommentView';
import {
  attachmentPreview,
  filterLinks,
  timeago
} from '../../helper/commonFunction';
import TaggedEmployees from '../taggedEmployeeList/TaggedEmployees';

function Kudos({
  postData,
  getPostList,
  wallActiveStatus,
  copyToClipboard,
  hideActions = false
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [addComment, setAddComment] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalHeader, setModalHeader] = useState('');
  const [isPostUpdated, setIsPostUpdated] = useState(false);
  const [popupView, setPopupView] = useState(null);
  const authState = useSelector((state) => state.login.loginDetails);
  const isWallMember = useSelector((state) => state.post.isWallMember);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  useEffect(() => {
    if (postData) {
      if (postData?.created_at !== postData?.post_order) {
        setIsPostUpdated(true);
      } else {
        setIsPostUpdated(false);
      }
    }
  }, [postData]);

  const onClickEditHandler = () => {
    setModalHeader('Edit Kudos');
    setPopupView('editView');
    setShowModal(true);
  };
  const onClickDeleteHandler = () => {
    setModalHeader('Delete Kudos');
    setPopupView('deleteView');
    setShowModal(true);
  };

  const handleClose = (result) => {
    if (result) {
      getPostList(true);
    }
    setShowModal(false);
    setPopupView(null);
  };

  const openComments = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="kudo-container">
      <div className="col-sm-12 mt-5">
        <CustomModal
          body={
            (['editView'].includes(popupView) && (
              <WallPostForms
                handleClose={handleClose}
                postData={postData}
                formView={popupView}
              />
            )) ||
            (popupView === 'deleteView' && (
              <DeleteWallPost
                handleClose={handleClose}
                postData={postData}
                getPostList={getPostList}
              />
            ))
          }
          showModal={showModal}
          heading={modalHeader}
          setShowModal={setShowModal}
          size="xl"
        />
        <Card className="wall-card">
          <CardHeader className="p-0">
            <div className="d-flex justify-content-between">
              <div className="d-flex">
                <Avatar
                  className="m-auto"
                  name={postData?.post_creator?.name}
                  round
                  maxInitials={3}
                  size="38"
                />
                <div className="px-3 d-flex flex-column justify-content-center">
                  <span className="fs-6 font-bold">
                    {postData?.post_creator?.name}
                  </span>
                </div>
                <div className="d-flex flex-column justify-content-center">
                  <span className="text-sub font-regular time-text">
                    {isPostUpdated && 'Updated '}
                    {timeago(
                      isPostUpdated
                        ? postData?.post_order
                        : postData?.created_at
                    )}
                  </span>
                </div>
              </div>
              {postData?.wall_name && (
                <div className="d-flex flex-column justify-content-cente justify-content-evenlyr">
                  <span className="text-sub font-semi-bold text-primary wall-name-tag ">
                    {postData?.wall_name}
                  </span>
                </div>
              )}
              {(authState.role === '0' &&
                postData.allowed_like_and_comment === 1) ||
              hideActions ? (
                ''
              ) : (
                <div className="d-flex align-items-center justify-content-evenly">
                  <Pin
                    postID={postData.post_id}
                    isPin={postData.is_pinned}
                    getPostList={getPostList}
                  />
                  <UncontrolledDropdown>
                    <DropdownToggle
                      nav
                      className="d-flex align-items-center p-0">
                      <span className="col-1 ms-2 pt-2 ps-1">
                        <i className="bi bi-three-dots text-dark fs-4" />
                      </span>
                    </DropdownToggle>
                    <DropdownMenu end>
                      {authState?.employee_id ===
                        postData?.post_creator?.employee_id &&
                        wallActiveStatus === 1 && (
                          <>
                            <DropdownItem onClick={onClickEditHandler}>
                              Edit
                            </DropdownItem>
                            <DropdownItem onClick={onClickDeleteHandler}>
                              Delete
                            </DropdownItem>
                          </>
                        )}
                      <DropdownItem
                        onClick={() => copyToClipboard(postData?.post_id)}>
                        Copy Link
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              )}
            </div>
          </CardHeader>
          <div className="content-container">
            <CardBody className="py-0 px-0">
              <div>
                <span
                  className="description-content card-text-content font-regular"
                  style={{ whiteSpace: 'pre-line' }}
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: filterLinks(postData?.post_description)
                  }}
                />
              </div>
              <div className="text-center mt-2">
                <img src={cheers} alt="cheers" />
              </div>
              {postData.post_attachments.length > 0 &&
                attachmentPreview(postData.post_attachments)}
            </CardBody>
            {authState.role === '0' &&
            postData.allowed_like_and_comment === 1 &&
            postData.comment_count === 0 &&
            !isPostUpdated &&
            postData?.tagged_employees?.length === 0 ? (
              ''
            ) : (
              <CardFooter className="d-flex flex-wrap gap-3 px-0 py-0 mt-3">
                <div className="d-flex flex-grow-1">
                  {!hideActions && (isWallMember || authState.role === '0') ? (
                    <div className="d-flex gap-3">
                      {authState.role === '0' &&
                      postData.allowed_like_and_comment === 1
                        ? ''
                        : wallActiveStatus === 1 && (
                            <>
                              <div>
                                <Likes likeDetails={postData} />
                              </div>
                              <div
                                id={`post-${postData.post_id}-cmt`}
                                aria-hidden="true"
                                role="button"
                                onClick={() => setAddComment(!addComment)}>
                                <i className="bi bi-chat-left-fill text-primary" />
                                <span className="px-2 text-sub font-regular hide-text">
                                  {' '}
                                  Comment
                                </span>
                                <Tooltip
                                  placement="top"
                                  isOpen={tooltipOpen}
                                  toggle={toggle}
                                  autohide={false}
                                  target={`post-${postData.post_id}-cmt`}>
                                  Post a comment
                                </Tooltip>
                              </div>
                            </>
                          )}
                      {postData.comment_count > 0 ? (
                        <div
                          aria-hidden="true"
                          role="button"
                          onClick={openComments}>
                          <Comments commentDetails={postData} />
                        </div>
                      ) : (
                        ''
                      )}
                      &nbsp;
                    </div>
                  ) : (
                    ''
                  )}
                  {postData?.tagged_employees?.length > 0 && (
                    <TaggedEmployees
                      taggedPeople={postData?.tagged_employees}
                    />
                  )}
                </div>
                {isPostUpdated && (
                  <div className="flex-grow-1 text-end">
                    <span className="text-sub font-regular time-text">
                      {'Created at: '}
                      {timeago(postData?.created_at)}
                    </span>
                  </div>
                )}
              </CardFooter>
            )}
            <div className="comments-container mt-2">
              {addComment && (
                <div className="m-4">
                  <Comment postData={postData} getPostList={getPostList} />
                </div>
              )}
              {isOpen && (
                <div className="">
                  <UserComments
                    postID={postData.post_id}
                    parentId={postData.parent_comment_id}
                    getPostList={getPostList}
                    isView={postData.allowed_like_and_comment}
                  />
                </div>
              )}
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
}

export default Kudos;
