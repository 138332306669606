import React, { Suspense, useEffect } from 'react';
import Cookies from 'js-cookie';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import { routes, subNavRoutes, publicRoute } from './routes';

import InjectAxiosInterceptors from './shared/components/InjectAxiosInterceptors/InjectAxiosInterceptors';
import Loader from './shared/components/loader/Loader';
import ScrollToTop from './ScrollToTop';
import './App.scss';
import { getEmployeeAssigednWallListActionCreator } from './redux/slices/LoginSlice';
import FrameBusting from './shared/components/frameBusting/FrameBusting';

const Header = React.lazy(() => import('./layout/header/Header'));
const SideBar = React.lazy(() => import('./layout/sideNav/SideNav'));

function App() {
  const dispatch = useDispatch();
  const authState = useSelector((state) => state.login.loginDetails);
  const isLoginLoading = useSelector((state) => state.login.loading);

  // for private routes accessable to all
  const createPrivateRoutes = (routesArray) => {
    // condition for protected routes
    if (authState) {
      // loop over routes array
      return routesArray?.map(({ path, component, allowedRoles }) => {
        // loop over allowedRoles array
        if (allowedRoles?.some((allowRole) => authState.role === allowRole)) {
          return <Route key={path} path={path} element={component} exact />;
        }
        return null;
      });
    }
    return null;
  };

  // for public routes accessable to all
  const creeatPublicRoute = (routesArray) =>
    routesArray?.map(({ path, component }) => (
      <Route key={path} path={path} element={component} exact />
    ));

  useEffect(() => {
    if (!authState) {
      Cookies.set('redirectURL', window.location.href);
    }
    if (authState)
      dispatch(
        getEmployeeAssigednWallListActionCreator(authState?.employee_id)
      );
  }, []);

  return (
    <Router>
      <ScrollToTop />
      <FrameBusting />
      <InjectAxiosInterceptors />
      <Suspense fallback={<Loader />}>
        <Header />
        {authState && <SideBar />}
        <main>
          <Loader show={isLoginLoading} />
          <div className="container-fluid">
            <div className="row">
              {/* {isLogin && ( */}
              <div
                className={authState ? 'set-sidebar col-xl-10 col-lg-9' : ''}>
                <Suspense fallback={<Loader />}>
                  <Routes>
                    {creeatPublicRoute(publicRoute)}
                    {authState && createPrivateRoutes(routes)}
                    {authState && createPrivateRoutes(subNavRoutes)}
                    {/* {routes.map(({ path, component }) => (
                    <Route key={path} path={path} element={component} exact />
                  ))}
                  {subNavRoutes.map(({ path, component }) => (
                    <Route key={path} path={path} element={component} exact />
                  ))} */}
                    <Route path="*" element={<Navigate to="/" replace />} />
                  </Routes>
                </Suspense>
              </div>
            </div>
          </div>
        </main>
        <div className="footer">
          <footer className="text-center text-muted">
            <small>© mentor_mentee_tool</small>
          </footer>
        </div>
      </Suspense>
    </Router>
  );
}

export default App;
