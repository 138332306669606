import { useState } from 'react';
import { Tooltip } from 'reactstrap';

function TaggedEmployees({ taggedPeople }) {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  return (
    <div id="tagged">
      <i className="bi bi-tag-fill tag-icon text-primary" aria-hidden="true" />
      <span className="px-2">
        <span className="text-sub font-regular"> {taggedPeople[0]?.name}</span>
        {taggedPeople?.length > 1 && (
          <span
            aria-hidden="true"
            role="button"
            onMouseOver={toggle}
            onFocus={toggle}
            className="text-sub font-regular"
            onMouseLeave={toggle}>
            {' and '}
            <span className="text-primary">
              {taggedPeople.length - 1} others{' '}
            </span>
          </span>
        )}
      </span>
      <Tooltip
        placement="right"
        isOpen={tooltipOpen}
        autohide={false}
        target="tagged">
        {taggedPeople.slice(1).map((employee) => (
          <span key={employee.employee_id}>
            {employee.name}
            <br />
          </span>
        ))}
      </Tooltip>
    </div>
  );
}
export default TaggedEmployees;
