export const BASIC_URL = process.env.REACT_APP_API_URL;
const SERVER_URL = {
  USER_LOGIN: `${BASIC_URL}/users/login`,
  MENTOR_LIST: `${BASIC_URL}/mentors/list`,
  MENTOR_CREATE: `${BASIC_URL}/mentors/create`,
  MENTOR_DELETE: `${BASIC_URL}/mentors/delete/`,
  MENTOR_UPDATE: `${BASIC_URL}/mentors/update/`,
  MENTOR_DETAIL: `${BASIC_URL}/mentors/mentor_details/`,
  MENTEE_LIST: `${BASIC_URL}/mentees/list`,
  MENTEE_CREATE: `${BASIC_URL}/mentees/create`,
  MENTEE_DELETE: `${BASIC_URL}/mentees/delete/`,
  MENTEE_UPDATE: `${BASIC_URL}/mentees/update/`,
  MENTEE_DETAIL: `${BASIC_URL}/mentees/mentee_details/`,
  STREAM_LIST: `${BASIC_URL}/api/streams-list`,
  QUALIFICATION_LIST: `${BASIC_URL}/api/qualifications-list`,
  JOIN_LOCATION_LIST: `${BASIC_URL}/api/join-locations-list`,
  ASSIGN_MENTEE_LIST: `${BASIC_URL}/mentors/assigned_mentees_list`,
  ASSIGN_MENTEE_CREATE: `${BASIC_URL}/mentors/assign_mentees`,
  ASSIGN_MENTEE_UPDATE: `${BASIC_URL}/mentors/update_assigned_mentees/`,
  ASSIGN_MENTEE_DELETE: `${BASIC_URL}/mentors/delete_assigned_mentees/`,
  USER_LIST: `${BASIC_URL}/users/list`,
  USER_CREATE: `${BASIC_URL}/users/create`,
  USER_DELETE: `${BASIC_URL}/users/delete/`,
  USER_UPDATE: `${BASIC_URL}/users/update/`,
  USER_DETAIL: `${BASIC_URL}/users/user_details/`,
  TOPIC_LIST: `${BASIC_URL}/topics/list`,
  TOPIC_CREATE: `${BASIC_URL}/topics/create`,
  TOPIC_DELETE: `${BASIC_URL}/topics/delete/`,
  TOPIC_UPDATE: `${BASIC_URL}/topics/update/`,
  TOPIC_DETAIL: `${BASIC_URL}/topics/topic_details/`,
  CURRICULUM_LIST: `${BASIC_URL}/curriculum/list`,
  CURRICULUM_CREATE: `${BASIC_URL}/curriculum/create`,
  CURRICULUM_DELETE: `${BASIC_URL}/curriculum/delete/`,
  CURRICULUM_UPDATE: `${BASIC_URL}/curriculum/update/`,
  CURRICULUM_DETAIL: `${BASIC_URL}/curriculum/curriculum_details/`,
  PROJECT_LIST: `${BASIC_URL}/projects/list`,
  PROJECT_CREATE: `${BASIC_URL}/projects/create`,
  PROJECT_DELETE: `${BASIC_URL}/projects/delete/`,
  PROJECT_UPDATE: `${BASIC_URL}/projects/update/`,
  PROJECT_DETAIL: `${BASIC_URL}/projects/project_details/`,
  BATCH_LIST: `${BASIC_URL}/batches/list`,
  BATCH_CREATE: `${BASIC_URL}/batches/create`,
  BATCH_DELETE: `${BASIC_URL}/batches/delete/`,
  BATCH_UPDATE: `${BASIC_URL}/batches/update/`,
  BATCH_DETAIL: `${BASIC_URL}/batches/batch_details/`,
  WALL_LIST: `${BASIC_URL}/walls/list`,
  WALL_LIST_DELETE: `${BASIC_URL}/walls/delete/`,
  WALL_LIST_CREATE: `${BASIC_URL}/walls/create`,
  WALL_LIST_UPDATE: `${BASIC_URL}/walls/update/`,
  WALL_FILTERED_LIST: `${BASIC_URL}/walls/wall_assign_walls_list/`,
  WALL_DETAILS: `${BASIC_URL}/walls/wall_details/`,
  ASSIGN_WALL_CREATE: `${BASIC_URL}/walls/wall_assign_create`,
  EMPLOYEE_ASSIGN_WALL_LIST: `${BASIC_URL}/walls/employee_walls_list`,
  ASSIGN_WALL_LIST: `${BASIC_URL}/walls/wall_assign_list`,
  ASSIGN_WALL_DELETE: `${BASIC_URL}/walls/wall_assign_delete/`,
  ASSIGN_WALL_DETAILS: `${BASIC_URL}/walls/wall_assign_details/`,
  ASSIGN_WALL_UPDATE: `${BASIC_URL}/walls/wall_assign_update/`,
  ASSIGN_WALL_JOIN_LIST: `${BASIC_URL}/users/categorized-list`,
  CURRICULUM_REPORT_LIST: `${BASIC_URL}/reports/curriculum_list/`,
  TASK_REPORT_LIST: `${BASIC_URL}/reports/wall_task_reports/`,
  PROJECT_REPORT_LIST: `${BASIC_URL}/reports/wall_project/`,
  CURRICULUM_STATUS_LIST: `${BASIC_URL}/reports/curriculum_status_list/`,
  POST_CURRICULUM_DETAIL: `${BASIC_URL}/walls/curriculum_details/`,
  POST_CURRICULUM_TOPIC_COMPLETE: `${BASIC_URL}/walls/complete_topic`,
  POST_ENDORSEMENT_CREATE: `${BASIC_URL}/walls/create_post_endorsement`,
  POST_ENDORSEMENT_UPDATE: `${BASIC_URL}/walls/update_post_endorsement/`,
  WALL_KUDOS_POST: `${BASIC_URL}/walls/create_post_kudos`,
  WALL_KUDOS_UPDATE: `${BASIC_URL}/walls/update_post_kudos/`,
  WALL_CURRICULUM_POST: `${BASIC_URL}/walls/create_post_curriculum`,
  WALL_CURRICULUM_UPDATE: `${BASIC_URL}/walls/update_post_curriculum/`,
  WALL_POST: `${BASIC_URL}/walls/create_post_posts`,
  WALL_POST_UPDATE: `${BASIC_URL}/walls/update_wall_post/`,
  WALL_TASK_POST: `${BASIC_URL}/walls/create_post_task`,
  WALL_TASK_UPDATE: `${BASIC_URL}/walls/update_post_task/`,
  WALL_TASK_STATUS_UPDATE: `${BASIC_URL}/walls/update_status/`,
  WALL_TASK_FEEDBACK: `${BASIC_URL}/walls/update_task_feedback/`,
  WALL_PROJECT_POST: `${BASIC_URL}/walls/create_post_project`,
  WALL_ACHIEVEMENT_POST: `${BASIC_URL}/walls/create_post_achievement`,
  WALL_ACHIEVEMENT_UPDATE: `${BASIC_URL}/walls/update_post_achievement/`,
  POST_THANKS_CREATE: `${BASIC_URL}/walls/create_post_thanks`,
  POST_THANKS_UPDATE: `${BASIC_URL}/walls/update_post_thanks/`,
  POST_LIST: `${BASIC_URL}/walls/list_wall_posts/`,
  POST_DETAIL: `${BASIC_URL}/posts/get_post_details/`,
  POST_MEETING_CREATE: `${BASIC_URL}/walls/create_post_meeting`,
  ASSIGN_SESSION_TRAINER_LIST: `${BASIC_URL}/`,
  ASSIGN_SESSION_SAVE: `${BASIC_URL}/session/create_session_assign`,
  ASSIGN_SESSION_DELETE: `${BASIC_URL}/session/delete_session_detail/`,
  ASSIGN_SESSION_SESSION_LIST: `${BASIC_URL}/session/list_session_detail`,
  ASSIGN_SESSION_UPDATE: `${BASIC_URL}/session/update_session_assign/`,
  ASSIGN_SESSION_ITEM_DETAIL: `${BASIC_URL}/session/list_assign_session/`,
  POST_PIN: `${BASIC_URL}/walls/pin_unpin_wallpost`,
  POST_COMMENT: `${BASIC_URL}/posts/comment_post`,
  UPDATE_COMMENT: `${BASIC_URL}/posts/comment_update/`,
  DELETE_COMMENT: `${BASIC_URL}/posts/comment_delete/`,
  MY_TEAM_LIST: `${BASIC_URL}/walls/team`,
  ASSIGN_SESSION_DETAIL: `${BASIC_URL}/session/list_assign_session/`,
  ASSIGN_SESSION_DETAIL_STATUS_MARKCOMPLETE: `${BASIC_URL}/session/update_session_assign_status/`,
  SESSION_CREATE: `${BASIC_URL}/session/create_sesion`,
  SESSION_DELETE: `${BASIC_URL}/session/delete_session/`,
  SESSION_UPDATE: `${BASIC_URL}/session/update_sesion/`,
  SESSION_LIST: `${BASIC_URL}/session/list_session`,
  USERS_LIST: `${BASIC_URL}/walls/mentor-mentee-mapping-list`,
  POST_LIKE: `${BASIC_URL}/posts/like_post`,
  POST_FEEDBACK: `${BASIC_URL}/walls/update_project_feedback/`,
  NOTIFICATIONS: `${BASIC_URL}/walls/notification-list/`,
  PROFILE_DETAILS: `${BASIC_URL}/users/user-profile-list/`,
  PROFILE_DETAILS_UPDATE: `${BASIC_URL}/users/update_profile/`,
  TAGGED_GENERAL_NOTIFICATION_ENABLE: `${BASIC_URL}/users/toggle-custom-notification`,
  TAGGED_NOTIFICATION_ENABLE: `${BASIC_URL}/users/create-custom-notification`,
  COMMENTS_LIST: `${BASIC_URL}/posts/comments_list/`,
  WALL_PROJECT_UPDATE: `${BASIC_URL}/walls/update_post_project/`,
  MEETING_UPDATE: `${BASIC_URL}/walls/update_post_meeting/`,
  WALL_PROJECT_UPDATE_STATUS: `${BASIC_URL}/walls/update_wall_project_status/`,
  REPORT_MENTOR: `${BASIC_URL}/mentors/posts_assigned_by_list`,
  REPORT_MENTEE: `${BASIC_URL}/mentees/list`,
  MENTEE_PROJECT_REPORT: `${BASIC_URL}/reports/project_allocation_report/`,
  COMMENT_LIKE: `${BASIC_URL}/posts/like_reply`,
  DELETE_POST: `${BASIC_URL}/walls/delete_wall_post/`,
  ASSIGN_WALL_SATUS_CHANGE: `${BASIC_URL}/walls/update_wall_assign_status/`,
  TASK_EXCEL_DOWNLOAD: `${BASIC_URL}/reports/wall_task_reports__report_excel_download/`,
  CURRICULUM_EXCEL_DOWNLOAD: `${BASIC_URL}/reports/curriculum_status_list_excel_download/`,
  PROJECT_EXCEL_DOWNLOAD: `${BASIC_URL}/reports/wall_project_excel_download/`,
  BATCH_EXCEL_DOWNLOAD: `${BASIC_URL}/reports/project_allocation_report_excel_download/`,
  USER_LOGOUT: `${BASIC_URL}/users/logout`,
  WALL_REPORT_POST_LIST: `${BASIC_URL}/reports/mentee_reports`,
  WALL_REPORT_POST_SHARE: `${BASIC_URL}/reports/create_performance_evaluation_report`,
  WALL_PERFORMANCE_REPORT_POST_LIST: `${BASIC_URL}/reports/performance-report/`,
  WALL_MEMEBER: `${BASIC_URL}/walls/is_wall_member/`,
  FEEDBACK_UPDATE: `${BASIC_URL}/walls/edit_post_feedback/`
};

export default SERVER_URL;
