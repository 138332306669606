import React, { useEffect } from 'react';
import { Button, Row, Col } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { feedBackUpdate } from '../../../redux/slices/PostSlice';
import Loader from '../../components/loader/Loader';
import InputField from '../../input/inputField/InputField';

function FeedbackForms({ handleClose, postData, feedback }) {
  const btnLoader = useSelector((state) => state.post.loading);
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue
    // clearErrors
  } = useForm();

  useEffect(() => {
    if (feedback) setValue('feedback', feedback?.feedback);
  }, []);

  const handleCancel = () => {
    handleClose();
  };

  const onSubmit = (data) => {
    const apiData = {
      body: {
        feedback: data.feedback,
        post_type: postData?.post_type,
        post_id: postData?.post_id
      }
    };
    apiData.id = feedback?.feedback_id;
    dispatch(feedBackUpdate(apiData))
      .unwrap()
      .then(() => {
        if (!btnLoader) {
          handleClose(apiData);
          //   getPostList(true, true);
        }
      });
  };

  return (
    <div className="delete-user-container">
      {btnLoader && <Loader show={btnLoader} />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row className="pt-3">
          <Col>
            <Controller
              name="feedback"
              control={control}
              rules={{
                required: true,
                pattern: {
                  value: /.*\S.*/,
                  message: 'Please enter valid text.'
                }
              }}
              render={({ field }) => (
                <InputField
                  errors={errors.feedback}
                  inputType="textarea"
                  field={field}
                  label="Feedback"
                  placeholder="feedback"
                  rows={5}
                />
              )}
            />
          </Col>
        </Row>

        <Row className="pt-3">
          <Col md="auto">
            <Button
              outline
              color="primary"
              disabled={btnLoader}
              onClick={handleCancel}>
              Cancel
            </Button>
          </Col>
          <Col md="auto">
            <Button color="primary" disabled={btnLoader}>
              Update
            </Button>
          </Col>
        </Row>
      </form>
    </div>
  );
}

export default FeedbackForms;
