/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import {
  getPostDetailActionCreator,
  getMyTeamListActionCreator
} from '../../redux/slices/PostSlice';
import { getCurriculumListActionCreator } from '../../redux/slices/CurriculumSlice';
import { getProjectsListActionCreator } from '../../redux/slices/ProjectsSlice';
import { post } from '../../shared/constants/ConstantsData';
import Loader from '../../shared/components/loader/Loader';
import { listFormatter } from '../../shared/helper/commonFunction';
import GeneralPostTypeField from '../../shared/wall/post-type-form/GeneralPostTypeField';
import WallCurriculumField from '../../shared/wall/wall-curriculum/WallCurriculumField';
import './WallPostForms.scss';
import WallTaskField from '../../shared/wall/task/WallTaskField';
import WallProjectField from '../../shared/wall/wall-project/WallProjectField';
import WallMeetingMinutesField from '../../shared/wall/meeting-minutes/WallMeetingMinutesField';

function WallPostForms({ handleClose, postData, formView }) {
  const myTeamList = useSelector((state) => state.post.myTeamList);
  const { wallID } = useParams();
  const curriculumListData = useSelector(
    (state) => state.curriculum.curriculumList
  );
  const projectListData = useSelector((state) => state.projects.projectsList);
  const postLoading = useSelector((state) => state.post.loading);
  const btnLoader = useSelector((state) => state.post.btnLoader);
  const postDetail = useSelector((state) => state.post.postDetail);
  const userLoading = useSelector((state) => state.user.loading);
  const curriculumListLoading = useSelector(
    (state) => state.curriculum.loading
  );
  const projectListLoading = useSelector((state) => state.projects.loading);
  const [users, setUsers] = useState([]);
  const [curriculumList, setCurriculumList] = useState([]);
  const [projectList, setProjectList] = useState([]);

  const dispatch = useDispatch();

  const getPostDetail = () => {
    dispatch(getPostDetailActionCreator({ postID: postData.post_id }));
  };

  useEffect(() => {
    dispatch(getMyTeamListActionCreator({ wallID }));
    if (postData?.post_type === 'Curriculum') {
      dispatch(getCurriculumListActionCreator({ wallID }));
    } else if (postData?.post_type === 'Project') {
      dispatch(getProjectsListActionCreator({ wallID }));
    }
    getPostDetail();
  }, []);

  const assignMentees = () => {
    const mentees = myTeamList.filter(
      (team) => team.designation.toUpperCase() === 'MENTEE'
    );
    return listFormatter(mentees, (team) => ({
      id: team.employee_id,
      value: team.name,
      label: team.name,
      email: team.email
    }));
  };

  useEffect(() => {
    // myTeamList.forEach((userData) => {
    //   if (
    //     userData.employee_id === authState.employee_id &&
    //     userData.designation.toUpperCase() === 'MENTEE'
    //   ) {
    //     setIsWallMentee(true);
    //   }
    // });

    if (myTeamList && myTeamList?.length) {
      const usersData = listFormatter(myTeamList, (user) => ({
        id: user.employee_id,
        value: user.name,
        label: user.name,
        email: user.email
      }));
      setUsers(usersData);
    }
  }, [myTeamList]);

  useEffect(() => {
    if (curriculumListData && curriculumListData?.length) {
      const curriculum = listFormatter(
        curriculumListData,
        (curriculumData) => ({
          id: curriculumData.curriculum_id,
          label: curriculumData.curriculum_name,
          value: curriculumData.curriculum_name
        })
      );
      setCurriculumList(curriculum);
    }
  }, [curriculumListData]);

  useEffect(() => {
    if (projectListData && projectListData?.length) {
      const projects = listFormatter(projectListData, (projectData) => ({
        id: projectData.project_id,
        value: `${projectData.project_name} - ${projectData.platform}`,
        label: `${projectData.project_name} - ${projectData.platform}`
      }));
      setProjectList(projects);
    }
  }, [projectListData]);

  const handleCancel = () => {
    handleClose(null);
  };

  const getPostTypeField = () => {
    switch (postDetail?.post_type) {
      case 'Curriculum':
        return (
          <WallCurriculumField
            curriculumList={curriculumList}
            postDetail={postDetail}
            users={users}
            assignees={assignMentees()}
            formView="editView"
            handleCancel={handleCancel}
            handleClose={handleClose}
          />
        );
      case 'Task':
        return (
          <WallTaskField
            postDetail={postDetail}
            users={users}
            assignees={assignMentees()}
            formView="editView"
            handleCancel={handleCancel}
            handleClose={handleClose}
          />
        );
      case 'Project':
        return (
          <WallProjectField
            postDetail={postDetail}
            users={users}
            assignees={assignMentees()}
            projectList={projectList}
            formView="editView"
            handleCancel={handleCancel}
            handleClose={handleClose}
          />
        );
      case 'Meeting Minutes':
        return (
          <WallMeetingMinutesField
            postDetail={postDetail}
            users={users}
            assignees={assignMentees()}
            formView="editView"
            handleCancel={handleCancel}
            handleClose={handleClose}
          />
        );
      default:
        return (
          <GeneralPostTypeField
            postDetail={postDetail}
            users={users}
            formView="editView"
            handleCancel={handleCancel}
            handleClose={handleClose}
          />
        );
    }
  };

  return (
    <div className="wall-post-form-container">
      <Loader
        show={
          postLoading ||
          userLoading ||
          curriculumListLoading ||
          projectListLoading ||
          btnLoader
        }
      />
      <div className="row ">
        <div className="col-sm-12 ">
          <div className="row wall-post-form-wrapper">
            <div>
              <div className="welcome-card">{getPostTypeField()}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default WallPostForms;
