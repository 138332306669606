import React from 'react';
import { Label } from 'reactstrap';
import DatePicker from 'react-datepicker';
import { getYear, getMonth } from 'date-fns';
import { validationMsg } from '../../helper/commonFunction';
import { months } from '../../constants/ConstantsData';
import './Input.scss';

function CustomDatepicker({
  field,
  label,
  errors,
  placeholder,
  dateFormat,
  timeInputLabel,
  showTimeInput,
  filterTime,
  showTimeSelect,
  timeIntervals = 5,
  showTimeSelectOnly,
  timeFormat = 'HH:mm',
  onChange,
  valueTime,
  startTime,
  startDate,
  endTime,
  autoComplete = 'off'
}) {
  const range = (start, end) =>
    new Array(end - start).fill().map((d, i) => i + start);
  const years = range(1990, getYear(new Date()) + 2, 1);
  return (
    <>
      {label && (
        <Label className="font-semi-bold" for={label}>
          {label}
        </Label>
      )}
      <DatePicker
        onKeyDown={(e) => {
          e.preventDefault();
        }}
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled
        }) => (
          <div
            style={{
              margin: 10,
              display: 'flex',
              justifyContent: 'center'
            }}>
            <button
              type="button"
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled}>
              {'<'}
            </button>
            <select
              value={getYear(date)}
              onChange={({ target: { value } }) => changeYear(value)}>
              {years.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <select
              value={months[getMonth(date)]}
              onChange={({ target: { value } }) =>
                changeMonth(months.indexOf(value))
              }>
              {months.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <button
              type="button"
              onClick={increaseMonth}
              disabled={nextMonthButtonDisabled}>
              {'>'}
            </button>
          </div>
        )}
        className="form-control font-regular"
        wrapperClassName="date-picker"
        dateFormat={dateFormat}
        placeholderText={placeholder}
        maxDate={new Date(new Date().getFullYear() + 1, 11, 31)}
        minDate={startDate}
        onChange={onChange}
        minTime={startTime || new Date().setHours(0, 0, 0)}
        maxTime={endTime || new Date().setHours(23, 59, 59)}
        {...field}
        selected={field?.value || field?.field?.value ? field.value : valueTime}
        timeInputLabel={timeInputLabel}
        showTimeInput={showTimeInput}
        timeFormat={timeFormat}
        filterTime={filterTime}
        showTimeSelect={showTimeSelect}
        timeIntervals={timeIntervals}
        showTimeSelectOnly={showTimeSelectOnly}
        autoComplete={autoComplete}
      />
      {errors && (
        <p className="input-field-error pt-2 mb-0 font-regular">
          {validationMsg(errors)}
        </p>
      )}
    </>
  );
}

export default CustomDatepicker;
