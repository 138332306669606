import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment/moment';
import { Button, Card, Row, Col } from 'reactstrap';
import { useForm, Controller } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { getUserListActionCreator } from '../../redux/slices/UserSlice';
import SelectField from '../../shared/input/selectField/SelectField';
import Loader from '../../shared/components/loader/Loader';
import CustomDatepicker from '../../shared/input/inputField/Datepicker';
import PostSection from '../../shared/wall/wall-post-section/PostSection';
import Kudos from '../../shared/wall/wall-kudos/Kudos';
import MeetingMinutes from '../../shared/wall/meeting-minutes/MeetingMinutes';
import Task from '../../shared/wall/task/Task';
import WallCurriculum from '../../shared/wall/wall-curriculum/WallCurriculum';
import Thanks from '../../shared/wall/thankyou/Thanks';
import Achievement from '../../shared/wall/Achievement/Achievement';
import Endorsement from '../../shared/wall/endorsement/Endorsement';
import WallProject from '../../shared/wall/wall-project/WallProject';
import {
  getReportPostListActionCreator,
  resetReportPostState,
  setIsWallMember,
  shareWallReportActionCreator,
  getPerformanceReportListActionCreator
} from '../../redux/slices/PostSlice';
import {
  //   MOMMENT_DATE_FORMAT_SLASH,
  DATE_FORMAT
} from '../../shared/constants/ConstantsData';
import {
  commonFormatter,
  formatOptionLabel
} from '../../shared/helper/commonDataFormatter';
import { getDateDifference } from '../../shared/helper/commonFunction';
import './WallReportList.scss';
import CustomModal from '../../shared/components/modal/Modal';

function WallReportList() {
  // const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const userList = useSelector((state) => state.user.userList);
  const wallReportPostListLoader = useSelector((state) => state.post.loading);
  const wallReportPostList = useSelector(
    (state) => state.post.wallReportPostList
  );
  const authState = useSelector((state) => state.login.loginDetails);

  const [postList, setPostList] = useState([]);
  const [sortOptions, setSortOptions] = useState([]);
  const [isWallMentee, setIsWallMentee] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [sortValue, setSortValue] = useState({
    id: 0,
    value: 'Wall Group',
    label: 'Wall Group'
  });

  const [startDate, setStartDate] = useState(null);
  const [selectedReportStartDate, setSelectedReportStartDate] = useState(null);
  const [selectedReportEndDate, setSelectedReportEndDate] = useState(null);
  const [userListData, setUserListData] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalHeader, setModalHeader] = useState('');
  const [popupView, setPopupView] = useState(null);
  const { performanceReportID } = useParams();
  const dispatch = useDispatch();

  const {
    control,
    handleSubmit,
    // eslint-disable-next-line no-unused-vars
    formState: { errors },
    getValues,
    setValue
  } = useForm();

  const {
    control: controlShare,
    formState: { errors: errorShare },
    handleSubmit: handleSubmitShare,
    reset
  } = useForm();

  const resetReport = () => {
    dispatch(resetReportPostState());
    setPostList([]);
    setIsWallMentee(false);
  };

  const getPerformanceReportList = (reportSortValue) => {
    const apiParams = {
      performanceReportID,
      reportID: reportSortValue?.id
    };
    dispatch(getPerformanceReportListActionCreator(apiParams));
  };

  useEffect(() => {
    if (performanceReportID) {
      getPerformanceReportList(sortValue);
    }
  }, [performanceReportID]);

  useEffect(() => {
    if (wallReportPostList?.length) {
      let postListData = [];
      wallReportPostList?.forEach((wallData) => {
        if (wallData?.posts) {
          wallData?.posts.forEach((postData) => {
            postListData = [
              ...postListData,
              {
                ...postData,
                wall_name: wallData?.wall_name ? wallData.wall_name : null
              }
            ];
          });
        } else {
          postListData = [
            ...postListData,
            {
              ...wallData
            }
          ];
        }
      });
      setPostList(postListData);
    }
  }, [wallReportPostList]);

  useEffect(() => {
    if (userList?.length) {
      const list = [...userList];
      list.sort((a, b) => {
        // Use toUpperCase() to ignore character casing
        const bandA = a.name.toUpperCase();
        const bandB = b.name.toUpperCase();

        let comparison = 0;
        if (bandA > bandB) {
          comparison = 1;
        } else if (bandA < bandB) {
          comparison = -1;
        }
        return comparison;
      });
      setUserListData(list);
    }
  }, [userList]);

  useEffect(() => {
    setSortOptions([
      {
        id: 0,
        value: 'Wall Group',
        label: 'Wall Group'
      },
      {
        id: 1,
        value: 'Last Updated',
        label: 'Last Updated'
      }
    ]);
    resetReport();
    if (!performanceReportID) dispatch(getUserListActionCreator());

    if (authState?.role === '1') {
      dispatch(setIsWallMember(true));
      setIsWallMentee(true);
    }
  }, []);

  const onSubmit = (data) => {
    setIsFormSubmitted(true);
    const startDateVal = getDateDifference(
      true,
      'days',
      0,
      new Date(data.start_date)
    );
    const endDateVal = getDateDifference(
      false,
      'days',
      0,
      new Date(data.end_date)
    );
    setSelectedReportStartDate(startDateVal);
    setSelectedReportEndDate(endDateVal);
    if (authState?.role === '0') setSelectedUser(data.employee_id.id);
    resetReport();
    const apiData = {
      body: {
        start_date: startDateVal,
        end_date: endDateVal
      }
    };

    if (sortValue?.value) {
      apiData.body.report_type = sortValue?.id;
    }
    if (authState?.role !== '0') {
      apiData.body.employee_id = authState?.employee_id;
    } else {
      apiData.body.employee_id = data?.employee_id?.id;
    }
    dispatch(getReportPostListActionCreator(apiData));
  };

  const handleClose = () => {
    reset();
    setShowModal(false);
    setPopupView(null);
  };

  const onSubmitShare = (data) => {
    const body = {
      start_date: selectedReportStartDate,
      end_date: selectedReportEndDate,
      employee_id: selectedUser,
      shared_ids: data.reporter_id.map((reporterId) => reporterId.id)
    };
    if (authState?.role !== '0') {
      body.employee_id = authState?.employee_id;
    } else {
      body.employee_id = selectedUser;
    }
    dispatch(shareWallReportActionCreator(body)).then(() => handleClose());
  };

  const startDateChange = (value) => {
    setValue('start_date', new Date(value));
    setValue('end_date', null);
    setStartDate(new Date(moment(value).add(0, 'day')));
  };
  const endDateChange = (value) => {
    setValue('end_date', new Date(value));
  };

  const onClickShare = () => {
    reset();
    setModalHeader('Share Wall Report');
    setPopupView('shareView');
    setShowModal(true);
  };

  const onSortChange = (data) => {
    setValue('report_type', data, { shouldValidate: true });
    setSortValue(data);
    if (performanceReportID) getPerformanceReportList(data);
  };

  useEffect(() => {
    if (sortValue && isFormSubmitted) onSubmit(getValues());
  }, [sortValue]);

  return (
    <div className="wall-report-container">
      <div className="row d-flex align-items-center justify-content-between">
        <div className="col-md-6">
          <h4 className="col-md-7 m-0">
            {performanceReportID ? 'Performance Report' : 'Wall Report'}
          </h4>
          <span className="header-underline" />
        </div>
        {postList?.length ? (
          <div className="col-md-3">
            <form>
              <Row>
                <Col>
                  <Controller
                    name="report_type"
                    control={control}
                    rules={{
                      required: false
                    }}
                    render={({ field: { onChange } }) => (
                      <SelectField
                        errors={errors.report_type}
                        options={sortOptions}
                        field={onChange}
                        onChange={onSortChange}
                        value={sortValue}
                        label="Sort by *"
                        placeholder="Sort by"
                        isMulti={false}
                      />
                    )}
                  />
                </Col>
              </Row>
            </form>
          </div>
        ) : (
          ''
        )}
      </div>
      {!performanceReportID && (
        <Card className="card-over mt-3">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row className="pt-3 pb-3">
              {authState?.role === '0' && (
                <Col md={4}>
                  <Controller
                    name="employee_id"
                    control={control}
                    rules={{
                      required: true
                    }}
                    render={(field) => (
                      <SelectField
                        errors={errors.employee_id}
                        options={commonFormatter(userListData)}
                        field={field.field}
                        label="Users *"
                        placeholder="Select User"
                        isMulti={false}
                        isClearable
                        formatOptionLabel={formatOptionLabel}
                      />
                    )}
                  />
                </Col>
              )}
              <Col md={authState?.role === '0' ? 4 : 6}>
                <Controller
                  name="start_date"
                  control={control}
                  rules={{
                    required: true
                  }}
                  render={({ field: { onChange, value } }) => (
                    <CustomDatepicker
                      errors={errors.start_date}
                      onChange={startDateChange}
                      valueTime={value}
                      field={onChange}
                      dateFormat={DATE_FORMAT}
                      label="Start Date *"
                      placeholder="DD/MM/YYYY"
                    />
                  )}
                />
              </Col>
              <Col md={authState?.role === '0' ? 4 : 6}>
                <Controller
                  name="end_date"
                  control={control}
                  rules={{
                    required: true
                  }}
                  render={({ field: { onChange, value } }) => (
                    <CustomDatepicker
                      errors={errors.end_date}
                      onChange={endDateChange}
                      valueTime={value}
                      field={onChange}
                      startDate={startDate}
                      // field={field}
                      dateFormat={DATE_FORMAT}
                      label="End Date *"
                      placeholder="DD/MM/YYYY"
                    />
                  )}
                />
              </Col>
              <Col
                sm="auto"
                className="report-btn-block mt-4 d-flex align-items-center">
                <Button color="primary" disabled={wallReportPostListLoader}>
                  Submit
                </Button>
              </Col>
              {postList.length ? (
                <Col
                  sm="auto"
                  className="report-btn-block mt-4 d-flex align-items-center">
                  <Button color="primary" onClick={onClickShare}>
                    Share Report
                  </Button>
                </Col>
              ) : (
                ''
              )}
            </Row>
          </form>
          {!wallReportPostListLoader && !postList?.length && isFormSubmitted ? (
            <span>No Record Found.</span>
          ) : (
            ''
          )}
        </Card>
      )}
      <Loader show={wallReportPostListLoader} />

      {!wallReportPostListLoader && !postList?.length && performanceReportID ? (
        <div className="mt-3">
          <span>No Record Found.</span>
        </div>
      ) : (
        ''
      )}

      {postList?.length ? (
        <div className="row">
          {/* {postList.length && !performanceReportID ? (
            <div className="d-flex justify-content-end shareReport align-items-center text-primary">
              <h5>Share Report</h5>
              <Button onClick={onClickShare} color="none" className="p-0 ms-4">
                <i className="bi bi-share-fill fs-4 text-primary" />
              </Button>
            </div>
          ) : (
            ''
          )} */}
          {postList?.map((postData) => {
            if (postData?.post_description && postData?.post_type === 'Post') {
              return (
                <PostSection
                  key={postData?.post_id}
                  postData={postData}
                  hideActions
                />
              );
            }
            if (postData?.post_description && postData?.post_type === 'Kudos') {
              return (
                <Kudos
                  key={postData?.post_id}
                  postData={postData}
                  hideActions
                />
              );
            }
            if (
              postData?.wall_curriculum &&
              postData?.post_type === 'Curriculum' &&
              Object.keys(postData?.wall_curriculum).length
            ) {
              return (
                <WallCurriculum
                  key={postData?.post_id}
                  postData={postData}
                  hideActions
                />
              );
            }
            if (
              postData?.wall_meeting_minute &&
              postData?.post_type === 'Meeting Minutes' &&
              Object.keys(postData?.wall_meeting_minute).length
            ) {
              return (
                <MeetingMinutes
                  key={postData?.post_id}
                  postData={postData}
                  hideActions
                />
              );
            }
            if (
              postData?.wall_task &&
              postData?.post_type === 'Task' &&
              Object.keys(postData?.wall_task).length
            ) {
              return (
                <Task
                  key={postData?.post_id}
                  postData={postData}
                  isWallMentee={isWallMentee}
                  hideActions
                />
              );
            }
            if (
              postData?.post_description &&
              postData?.post_type === 'Thanks'
            ) {
              return (
                <Thanks
                  key={postData?.post_id}
                  postData={postData}
                  hideActions
                />
              );
            }
            if (
              postData?.post_description &&
              postData?.post_type === 'Achievement'
            ) {
              return (
                <Achievement
                  key={postData?.post_id}
                  postData={postData}
                  hideActions
                />
              );
            }
            if (
              postData?.post_description &&
              postData?.post_type === 'Endorsement'
            ) {
              return (
                <Endorsement
                  key={postData?.post_id}
                  postData={postData}
                  hideActions
                />
              );
            }
            if (
              postData?.wall_project &&
              postData?.post_type === 'Project' &&
              Object.keys(postData?.wall_project).length
            ) {
              return (
                <WallProject
                  key={postData?.post_id}
                  postData={postData}
                  isWallMentee={isWallMentee}
                  hideActions
                />
              );
            }
            return '';
          })}
        </div>
      ) : (
        ''
      )}

      <CustomModal
        body={
          ['shareView'].includes(popupView) && (
            <form onSubmit={handleSubmitShare(onSubmitShare)}>
              <Row>
                <Col md={12}>
                  <Controller
                    name="reporter_id"
                    control={controlShare}
                    rules={{
                      required: true
                    }}
                    render={(field) => (
                      <SelectField
                        errors={errorShare.reporter_id}
                        options={commonFormatter(userListData)}
                        field={field.field}
                        label="Reporter"
                        placeholder="Select Reporter"
                        isMulti
                        isClearable
                        formatOptionLabel={formatOptionLabel}
                      />
                    )}
                  />
                </Col>
                <Col md={12} className="mt-3">
                  <Button color="primary">Share report</Button>
                </Col>
              </Row>
            </form>
          )
        }
        showModal={showModal}
        heading={modalHeader}
        setShowModal={setShowModal}
        size="xs"
      />
    </div>
  );
}

export default WallReportList;
