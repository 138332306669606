import { configureStore } from '@reduxjs/toolkit';
import MentorReducer from './slices/MentorSlice';
import MenteeReducer from './slices/MenteeSlice';
import CommonSlice from './slices/CommonSlice';
import LoginSlice from './slices/LoginSlice';
import UserSlice from './slices/UserSlice';
import AssignMenteeSlice from './slices/AssignMenteeSlice';
import TopicSlice from './slices/TopicSlice';
import AssignWallReducer from './slices/AssignWallSlice';
import CurriculumSlice from './slices/CurriculumSlice';
import ProjectsSlice from './slices/ProjectsSlice';
import BatchSlice from './slices/BatchSlice';
import WallListReducer from './slices/WallListSlice';
import ReportSlice from './slices/ReportSlice';
import PostSlice from './slices/PostSlice';
import AssignSessionSlice from './slices/AssignSessionSlice';
import SessionSlice from './slices/SessionSlice';
import ProfileSlice from './slices/ProfileSlice';
// import assignSessionSlice from './slices/AssignSession';
import CommentsSlice from './slices/CommetsSlice';

const store = configureStore({
  reducer: {
    mentor: MentorReducer,
    mentee: MenteeReducer,
    user: UserSlice,
    login: LoginSlice,
    common: CommonSlice,
    assignMentee: AssignMenteeSlice,
    topic: TopicSlice,
    assignWall: AssignWallReducer,
    curriculum: CurriculumSlice,
    projects: ProjectsSlice,
    batch: BatchSlice,
    wallList: WallListReducer,
    report: ReportSlice,
    post: PostSlice,
    assignSessions: AssignSessionSlice,
    session: SessionSlice,
    profile: ProfileSlice,
    comments: CommentsSlice
    // assignSession: assignSessionSlice
  }
});

export default store;
