export const DATE_TIME = 'dd/MM/yyyy HH:mm'; // MM - months and mm is minutes
export const TIME_ONLY = 'h:mm aa';
export const DATE_ONLY = 'dd/MM/yyyy';

export const DATE_TIME_MINUTE_SECOND = 'YYYY-MM-DD HH:mm:ss';
export const DATE_TIME_MINUTE_SECOND_TIMEZONE = 'YYYY-MM-DD HH:mm:ss Z';

export const DATE_FORMAT = 'dd/MM/yyyy';
export const MOMMENT_DATE_FORMAT = 'DD/MM/yyyy';
export const MOMMENT_DATE_FORMAT_SLASH = 'yyyy-MM-DD';
export const MOMENT_DATE_TIME = 'DD/MM/YYYY, HH:mm';
export const MOMENT_MONTH_DAY_YEAR = 'MMM DD YYYY';
export const COOKIE_SECRET_KEY = process.env.REACT_APP_COOKIE_SECRET_KEY;

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];
export const status = [
  { id: '1', value: 'Available', label: 'Available' },
  { id: '2', value: 'Not Available', label: 'Not Available' }
];
export const curriculum = [
  { id: 1, value: 'Curriculum JS', label: 'Curriculum JS ' },
  { id: 2, value: 'Curriculum Java', label: 'Curriculum Java ' },
  { id: 3, value: 'Curriculum PHP', label: 'Curriculum PHP ' }
];

export const tasks = [
  { id: 1, value: 'Ubuntu Installation', label: 'Ubuntu Installation' },
  { id: 2, value: 'How Linux works', label: 'How Linux works' },
  { id: 3, value: 'LAMP stack', label: 'LAMP stack' },
  { id: 4, value: 'HTTP, Apache', label: 'HTTP, Apache' },
  { id: 5, value: 'Internet basics', label: 'Internet basics' },
  { id: 6, value: 'GIT', label: 'GIT' },
  { id: 7, value: 'Intro to Databases', label: 'Intro to Databases' },
  {
    id: 8,
    value: 'Advanced queries in Databases',
    label: 'Advanced queries in Databases'
  },
  { id: 9, value: 'Introduction to PHP', label: 'Introduction to PHP' },
  { id: 10, value: 'Advanced PHP', label: 'Advanced PHP' }
];

export const platforms = [
  { id: '1', value: 'Web', label: 'Web' },
  { id: '2', value: 'iOS', label: 'iOS' },
  { id: '3', value: 'Android', label: 'Android' }
];

export const post = [
  { id: '1', value: 'Kudos', label: 'Kudos' },
  { id: '2', value: 'Post', label: 'Post' },
  { id: '3', value: 'Curriculum', label: 'Curriculum' },
  { id: '4', value: 'Task', label: 'Task' },
  { id: '5', value: 'Achievement', label: 'Achievement' },
  { id: '6', value: 'Endorsement', label: 'Endorsement' },
  { id: '7', value: 'Project', label: 'Project' },
  { id: '8', value: 'Meeting Minutes', label: 'Meeting Minutes' },
  { id: '9', value: 'Thanks', label: 'Thanks' }
];

export const project = [
  { id: '1', value: 'Project 1', label: 'Project 1' },
  { id: '2', value: 'Project 2', label: 'Project 2' },
  { id: '3', value: 'Project 3', label: 'Project 3' }
];

export const rating = [
  { id: '0.5', value: '0.5', label: '0.5' },
  { id: '1', value: '1', label: '1' },
  { id: '1.5', value: '1.5', label: '1.5' },
  { id: '2', value: '2', label: '2' },
  { id: '2.5', value: '2.5', label: '2.5' },
  { id: '3', value: '3', label: '3' },
  { id: '3.5', value: '3.5', label: '3.5' },
  { id: '4', value: '4', label: '4' },
  { id: '4.5', value: '4.5', label: '4.5' },
  { id: '5', value: '5', label: '5' }
];

export const feedbackData = [
  {
    id: 1,
    name: 'Abhijith Sajeevan',
    rating: 3.5,
    comments:
      'Aperiam vitae cupiditate natus accusamus qui, adipisci in consectetur quasi.'
  },
  {
    id: 2,
    name: 'Cheran Mathivanan',
    rating: 4,
    comments:
      'Aperiam vitae cupiditate natus accusamus qui, adipisci in consectetur quasi.'
  }
];

export const assigneeStatus = [
  { id: 1, value: 'Assigned', label: 'Assigned' },
  { id: 2, value: 'In Progress', label: 'In Progress' },
  { id: 3, value: 'Completed', label: 'Completed' }
];

export const people = [
  { id: 1, value: 'Abhijith Sajeevan', label: 'Abhijith Sajeevan' },
  { id: 2, value: 'Bharathy RameshBabu', label: 'Bharathy RameshBabu' },
  { id: 3, value: 'Cheran Mathivanan', label: 'Cheran Mathivanan' },
  { id: 4, value: 'Feba Baby', label: 'Feba Baby' },
  { id: 5, value: 'Roohie Tasleem', label: 'Roohie Tasleem' },
  { id: 5, value: 'Joyal James', label: 'Joyal James' },
  { id: 1, value: 'Abhijith ', label: 'Abhijith ' },
  { id: 2, value: 'Bharathy ', label: 'Bharathy ' },
  { id: 3, value: 'Cheran ', label: 'Cheran ' },
  { id: 4, value: 'Feba ', label: 'Feba ' },
  { id: 5, value: 'Roohie ', label: 'Roohie ' },
  { id: 5, value: 'Joyal ', label: 'Joyal ' }
];

export const sessionStatus = [
  { id: 1, value: 'Not completed', label: 'Not Completed' },
  { id: 2, value: 'Completed', label: 'Completed' }
];
export const sessions = [
  { session_id: '1', value: 'Induction', session_name: 'Induction' },
  {
    session_id: '2',
    value: 'Internet Basics ',
    session_name: 'Internet Basics '
  },
  { session_id: '3', value: 'GIT - 1', session_name: 'GIT - 1' },
  {
    session_id: '4',
    value: 'DBMS - 1 - Basics',
    session_name: 'DBMS - 1 - Basics'
  },
  { session_id: '5', value: 'HTML tags', session_name: 'HTML tags' },
  { session_id: '6', value: 'CSS Basics', session_name: 'CSS Basics' },
  { session_id: '7', value: 'JavaScript - 1', session_name: 'JavaScript - 1' },
  { session_id: '8', value: 'Basics of QA', session_name: 'Basics of QA' },
  { session_id: '9', value: 'DevOps', session_name: 'DevOps' }
];
